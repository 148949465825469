<template>
  <div>
  <div id="marquee" class="marquee">
    <span class="flix-html-strong flix-text-success">
      {{ showDates }}
    </span>
  </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    serial: Array
  },
  data () {
    return {
      dates: [],
      showDates: ''
    }
  },
  methods: {
    getDates () {
      this.dates = []
      JSON.parse(JSON.stringify(this.serial)).forEach(function (s, i) {
        this.dates[i] = this.$getNullsBefore(s.begin.d) + '.' + this.$getNullsBefore(s.begin.m) + '.' + s.begin.Y + ', ' + this.$getNullsBefore(s.begin.H) + ':' + this.$getNullsBefore(s.begin.i)
        if (s.begin.Y !== s.end.Y || s.begin.m !== s.end.m || s.begin.d !== s.end.d) {
          this.dates[i] += ' ' + this.$t('message.oClock') + ' – '
          this.dates[i] += this.$getNullsBefore(s.end.d) + '.' + this.$getNullsBefore(s.end.m) + '.' + s.end.Y + ', ' + this.$getNullsBefore(s.end.H) + ':' + this.$getNullsBefore(s.end.i) + ' ' + this.$t('message.oClock')
        } else if (s.begin.H !== s.end.H || s.begin.i !== s.end.i) {
          this.dates[i] += ' – '
          this.dates[i] += this.$getNullsBefore(s.end.H) + ':' + this.$getNullsBefore(s.end.i) + ' ' + this.$t('message.oClock')
        } else {
          this.dates[i] += ' ' + this.$t('message.oClock')
        }
      }.bind(this))
      this.showDates = this.dates.join(' –––– ')
    }
  },
  mounted () {
    this.getDates()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .marquee
    max-width: 100%
    white-space: nowrap
    overflow: hidden

  .marquee span
    display: inline-block
    padding-left: 100%
    text-align: right
    animation: marquee 20s linear infinite reverse

  .marquee span:hover
    animation-play-state: paused

  @keyframes marquee
    0%
      transform: translateX(0)
    100%
      transform: translateX(-100%)
</style>
